<template>
  <div
    id="dragging_container"
    :style="{
      transformOrigin: `${-translateX}px ${-translateY}px`,
      transform: `translate(${translateX}px, ${translateY}px) scale(${scale}) rotate(${rotation}deg)`,
    }"
    @mousedown="startDragging"
    @mousemove="onDrag"
    @mouseup="stopDragging"
    @mouseleave="stopDragging"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
    style="position: absolute"
    :class="{ dragging: dragging }">
    <div id="NaviMap" class="map" style="--pos-x: 0px; --pos-y: 0px">
      <img src="@/assets/img/map.png" draggable="false" />

      <!-- Map Canvas -->
      <div id="MapCanvas">
        <!-- <div
          v-for="point in mapPoints"
          :key="point.id"
          :style="{
            '--x': point.x + 'px',
            '--y': point.y + 'px',
            background: point.active
              ? 'orange'
              : point.visited
              ? 'green'
              : 'transparent',
          }"
          class="mapPoint testing"></div> -->
        <div
          v-for="point in path"
          :key="point.id"
          :style="{
            '--x': point.x + 'px',
            '--y': point.y + 'px',
          }"
          class="mapPoint"
          :class="{ active: point.active, visited: point.visited }"></div>

        <div
          v-for="connector in pathConnectors"
          :key="connector.id"
          :style="{
            ...getConnectorStyle(connector),
          }"
          class="mapConnector"
          :class="{ visited: connector.visited }"></div>

        <div
          v-if="start.p !== null"
          :style="{
            '--x': mapPoints[start.p].x + 'px',
            '--y': mapPoints[start.p].y + 'px',
          }"
          class="mapPin pinStart testing"></div>

        <div
          v-if="end.p !== null"
          :style="{
            '--x': mapPoints[end.p].x + 'px',
            '--y': mapPoints[end.p].y + 'px',
          }"
          class="mapPin pinEnd testing"></div>
      </div>
    </div>
  </div>
</template>

<script>
import mapPoints from '@/assets/data/points.json';
import mapConnectors from '@/assets/data/connectors.json';

export default {
  name: 'NaviMap',
  props: {
    selectedRoom: {
      type: Object,
      required: false,
    },
    start: {
      type: Object,
      required: true,
    },
    end: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
      mapPoints,
      connectors: mapConnectors,
      scale: 1, // Initial scale for pinch-zoom
      translateX: -850,
      translateY: -650,
      touchData: null, // For tracking pinch-zoom touch events
      dragData: null, // For tracking drag events
      selectedPoint: null,
      queue: [],
      visited: [],
      distanceTable: [],

      path: [],
      currentPoint: -1,
      rotation: 0,
    };
  },
  computed: {
    filteredIntersectionPath() {
      const filtered = this.path.filter((dot) => dot.intersection === true);

      if (this.path.length > 0 && filtered[0] !== this.path[0]) {
        filtered.unshift(this.path[0]);
      }

      const lastPoint = this.path[this.path.length - 1];
      if (this.path.length > 0 && filtered[filtered.length - 1] !== lastPoint) {
        filtered.push(lastPoint);
      }

      return filtered;
    },
    pathConnectors() {
      if (this.path.length < 2) return []; // Brak połączeń, jeśli ścieżka ma mniej niż 2 punkty.
      return this.path.slice(0, -1).map((point, index) => {
        const nextPoint = this.path[index + 1];
        return {
          id: `${point.id}-${nextPoint.id}`,
          p1: point,
          p2: nextPoint,
          visited: point.visited,
        };
      });
    },
  },
  methods: {
    // Pinch-Zoom Handlers
    handleTouchStart(event) {
      if (event.touches.length === 2) {
        const [touch1, touch2] = event.touches;
        this.touchData = {
          initialDistance: this.getDistance(touch1, touch2),
          initialScale: this.scale,
          initialCenter: {
            x: (touch1.clientX + touch2.clientX) / 2,
            y: (touch1.clientY + touch2.clientY) / 2,
          },
        };
      } else if (event.touches.length === 1) {
        const touch = event.touches[0];
        this.dragData = {
          startX: touch.clientX - this.translateX,
          startY: touch.clientY - this.translateY,
        };
      }
      this.dragging = true;
    },
    handleTouchMove(event) {
      if (this.touchData && event.touches.length === 2) {
        const [touch1, touch2] = event.touches;
        const currentDistance = this.getDistance(touch1, touch2);
        const scaleChange = currentDistance / this.touchData.initialDistance;
        const newScale = Math.min(
          Math.max(this.touchData.initialScale * scaleChange, 0.5),
          3,
        ); // Clamped between 0.5 and 3

        const centerX = (touch1.clientX + touch2.clientX) / 2;
        const centerY = (touch1.clientY + touch2.clientY) / 2;

        const deltaX = centerX - this.touchData.initialCenter.x;
        const deltaY = centerY - this.touchData.initialCenter.y;

        this.translateX += deltaX * (1 - newScale / this.scale);
        this.translateY += deltaY * (1 - newScale / this.scale);

        this.scale = newScale;
        this.touchData.initialCenter = { x: centerX, y: centerY };
      } else if (this.dragData && event.touches.length === 1) {
        const touch = event.touches[0];
        this.translateX = touch.clientX - this.dragData.startX;
        this.translateY = touch.clientY - this.dragData.startY;
      }
      this.dragging = true;
    },
    handleTouchEnd() {
      this.touchData = null;
      this.dragData = null;
      this.dragging = false;
    },
    getDistance(touch1, touch2) {
      const dx = touch2.clientX - touch1.clientX;
      const dy = touch2.clientY - touch1.clientY;
      return Math.sqrt(dx * dx + dy * dy);
    },

    // Mouse Drag Handlers
    startDragging(event) {
      this.dragData = {
        startX: event.clientX - this.translateX,
        startY: event.clientY - this.translateY,
      };
      this.dragging = true;
    },
    onDrag(event) {
      if (this.dragData) {
        this.translateX = event.clientX - this.dragData.startX;
        this.translateY = event.clientY - this.dragData.startY;
      }
      this.dragging = true;
    },
    stopDragging() {
      this.dragData = null;
      this.dragging = false;
    },

    // Map Centering
    updateMapPosition(p) {
      this.scale = 1;
      if (p && p.x && p.y) {
        this.translateX = -p.x;
        this.translateY = -p.y;
      } else {
        this.translateX = -850;
        this.translateY = -650;
      }
    },

    updateMapRotation() {
      if (
        this.currentPoint < 0 ||
        this.currentPoint >= this.filteredIntersectionPath.length - 1
      ) {
        this.rotation = 0;
        return;
      }

      const current = this.filteredIntersectionPath[this.currentPoint];
      const currentPointIndexInPath = this.path.findIndex(
        (point) => point === current,
      );
      const next = this.path[currentPointIndexInPath + 1];

      const targetAngle =
        90 -
        Math.atan2(current.y - next.y, current.x - next.x) * (180 / Math.PI);

      // Calculate the shortest rotation path
      const diff = (targetAngle - this.rotation) % 360;
      const shortestPath =
        diff > 180 ? diff - 360 : diff < -180 ? diff + 360 : diff;

      this.rotation += shortestPath;
    },

    // Connector and Map Position Updates
    getConnectorStyle(connector) {
      const p1 = connector.p1;
      const p2 = connector.p2;

      const distance = Math.sqrt(
        Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2),
      );

      const angle = 90 + Math.atan2(p2.y - p1.y, p2.x - p1.x) * (180 / Math.PI);

      return {
        '--x1': `${p1.x}px`,
        '--y1': `${p1.y}px`,
        '--x2': `${p2.x}px`,
        '--y2': `${p2.y}px`,
        '--distance': `${distance}px`,
        '--r': `${angle}deg`,
      };
    },
    calculateDistance(p1, p2) {
      return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    },

    // Distance Table Preparation
    prepareDistanceTable() {
      for (let i = 0; i < this.mapPoints.length; i++) {
        let row = { pointID: i };
        for (let j = 0; j < this.mapPoints.length; j++) {
          row['distanceToPoint-' + j] = Infinity;
          row['nextToPoint-' + j] = null;
        }
        this.distanceTable.push(row);
      }
      for (let i = 0; i < this.mapPoints.length; i++) {
        this.distanceTable[i]['distanceToPoint-' + i] = 0;
        this.distanceTable[i]['nextToPoint-' + i] = i;

        let connectors = this.connectors.filter(
          (c) => c.p1 === i || c.p2 === i,
        );
        connectors.forEach((c) => {
          let next = c.p1 === i ? c.p2 : c.p1;
          this.distanceTable[i]['distanceToPoint-' + next] =
            this.calculateDistance(this.mapPoints[i], this.mapPoints[next]);
          this.distanceTable[i]['nextToPoint-' + next] = next;
          this.distanceTable[i]['connectorToPoint-' + next] = c;
        });
      }

      for (let from = 0; from < this.mapPoints.length; from++)
        for (let to = 0; to < this.mapPoints.length; to++)
          for (let through = 0; through < this.mapPoints.length; through++) {
            let distance =
              this.distanceTable[from]['distanceToPoint-' + through] +
              this.distanceTable[through]['distanceToPoint-' + to];

            if (distance < this.distanceTable[from]['distanceToPoint-' + to]) {
              this.distanceTable[from]['distanceToPoint-' + to] = distance;
              this.distanceTable[from]['nextToPoint-' + to] =
                this.distanceTable[from]['nextToPoint-' + through];
              this.distanceTable[from]['connectorToPoint-' + to] =
                this.distanceTable[from]['connectorToPoint-' + through];
            }
          }
    },

    // Pathfinding using Distance Table
    useDistanceTable() {
      // console.log('Using Distance Table');
      this.mapPoints.forEach((point) => (point.visited = false));
      this.connectors.forEach((connector) => (connector.visited = false));

      let from = this.start.p;
      let to = this.end.p;
      let path = [];

      while (from != to && from !== null && to !== null) {
        this.mapPoints[from].visited = true;

        const connector = this.distanceTable[from]['connectorToPoint-' + to];
        if (connector !== null && connector !== undefined && connector.id) {
          this.connectors[connector.id].visited = true;
        }

        path.push(Number(from));
        from = this.distanceTable[from]['nextToPoint-' + to];

        if (from === null || from === undefined) {
          alert('No path exists.');
          break;
        }
      }
      if (from == to) {
        path.push(Number(to));
        this.mapPoints[to].visited = true;
      }
      var points = require('@/assets/data/points.json');
      this.path = path.map((point) => points.find((p) => p.id == point));

      this.currentPoint = 0;
      // get the path distance
      const pathDistance = this.path.reduce((acc, point, index) => {
        if (index === 0 || !point || !this.path[index - 1]) return acc;
        const prevPoint = this.path[index - 1];
        return (
          acc +
          Math.sqrt(
        Math.pow(point.x - prevPoint.x, 2) +
          Math.pow(point.y - prevPoint.y, 2),
          )
        );
      }, 0);

      // assuming average walkign speed fo 6km/h make the eta in minutes
      const eta = Math.round((pathDistance / 1000 / 6) * 60);
      this.$emit('updateEta', eta);
    },

    // Add a New Point to Map
    addPoint(event) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.mapPoints.push({
        id: this.mapPoints.length,
        x,
        y,
        visited: false,
      });
    },

    // Save Points and Connections
    savePointsAndConnections() {
      localStorage.setItem('mapPoints', JSON.stringify(this.mapPoints));
      localStorage.setItem('connectors', JSON.stringify(this.connectors));
    },

    // Point Selection
    selectPoint(point) {
      if (this.selectedPoint === null) {
        this.selectedPoint = point;
        this.selectedPoint.active = true;
      } else if (this.selectedPoint == point) {
        this.selectedPoint.active = false;
        this.selectedPoint = null;
      } else {
        this.connectors.push({
          id: this.connectors.length,
          p1: this.selectedPoint.id,
          p2: point.id,
          visited: false,
        });
        this.selectedPoint.active = false;
        this.selectedPoint = null;
      }
    },
    updateNavigation() {
      let visited = true;
      this.path.forEach((point) => {
        point.active = false;
        if (point == this.filteredIntersectionPath[this.currentPoint]) {
          visited = false;
          point.active = true;
        }
        point.visited = visited;
      });
    },
  },
  watch: {
    selectedRoom(newRoom) {
      // console.log('WATCHER IN NAVIMAP selectedRoom:', newRoom);
      if (newRoom && newRoom.name && newRoom.name.length > 0) {
        // this.updateMapPosition(newRoom);
        return;
      }
    },
    end(newEnd) {
      if (newEnd.p !== null) {
        this.updateMapPosition(
          this.mapPoints[newEnd.p].x,
          this.mapPoints[newEnd.p].y,
        );
      }
    },
    start(newStart) {
      // console.log('WATCHER IN NAVIMAP start:', newStart);

      if (newStart.p == null || this.end.p == null) {
        // clear path from being displayed on map
        this.path = [];
      }
      if (newStart.p !== null && this.end.p !== null) {
        this.useDistanceTable();
      }
    },

    modelValue(newValue) {
      console.log('WATCHER IN NAVIMAP modelValue:', newValue);
      this.path = newValue.path;
      this.currentPoint = newValue.currentPoint;
      this.updateNavigation();
    },
    path(newValue) {
      this.$emit('update:modelValue', {
        path: newValue,
        currentPoint: this.currentPoint,
      });
      this.updateNavigation();
    },
    currentPoint(newValue) {
      this.$emit('update:modelValue', {
        path: this.path,
        currentPoint: newValue,
      });
      this.updateNavigation();
      if (this.filteredIntersectionPath[newValue])
        // calculate the rotation of the connector piece
        this.updateMapRotation();
      this.updateMapPosition(this.filteredIntersectionPath[newValue]);
    },
  },
  mounted() {
    this.queue.push({ p: this.start.p, parent: null });
    this.prepareDistanceTable();
    // this.useDistanceTable();
  },
};
</script>

<style scoped>
@import url('@/assets/css/NaviMap.css');
</style>
