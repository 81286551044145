<template>
  <div id="DynamicIsland">
    <div
      id="IslandScreenSearch"
      style="display: flex"
      :style="islandHeight"
      v-if="path.length == 0">
      <div class="_1st">
        <input
          type="text"
          placeholder="Search..."
          v-model="searchInput"
          @input="handleInput"
          :disabled="isRoomSelected"
          id="searchInput" />
        <img
          src="@/assets/svg/search.svg"
          class="IconButton"
          v-if="!showRemoveIcon && !navigateButtonClicked"
          id="searchIcon" />
        <img
          src="@/assets/svg/exit.svg"
          class="IconButton"
          v-if="showRemoveIcon"
          id="cancel"
          @click="removeSelectedRoom" />
      </div>
      <hr
        v-if="navigateButtonClicked"
        style="
          height: 0.4rem;
          width: 100%;
          border-radius: 10vw;
          opacity: 0.4;
          background: #000;
        " />
      <div>
        <input
          id="fromInput"
          type="text"
          placeholder="From"
          v-model="searchInputFrom"
          @input="handleFromInput"
          v-if="navigateButtonClicked" />
      </div>
    </div>
    <br />
    <div v-if="path.length > 0" class="navigationScreen">
      <img
        src="@/assets/svg/arrow.svg"
        class="arrow_left"
        @click="decreaseCurrentPoint"
        style="transform: rotate(180deg)" />
      <div class="dots">
        <div
          class="dot"
          ref="dot"
          v-for="(dot, index) in filteredIntersectionPath"
          :key="index"
          :class="{
            visited: currentPoint > index,
            active: currentPoint == index,
          }"></div>
      </div>
      <img
        :src="arrowOrFinishFlag"
        class="arrow_right"
        @click="increaseCurrentPoint" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicIsland',
  data() {
    return {
      searchInput: '',
      searchInputFrom: '',
      showRemoveIcon: false,
      islandHeight: { height: '4rem' },

      path: [],
      pathLength: -1,
      currentPoint: -1,
    };
  },
  props: {
    selectedRoom: {
      type: Object,
      required: false,
    },
    navigateButtonClicked: {
      type: Boolean,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isRoomSelected() {
      return (
        this.selectedRoom &&
        this.selectedRoom.name &&
        this.selectedRoom.name.length > 0
      );
    },
    arrowOrFinishFlag() {
      return this.currentPoint == this.filteredIntersectionPath.length - 1
        ? require('@/assets/svg/finish.svg')
        : require('@/assets/svg/arrow.svg');
    },
    filteredIntersectionPath() {
      const filtered = this.path.filter((dot) => dot.intersection === true);

      // Dodaj pierwszy punkt, jeśli nie jest już zawarty
      if (this.path.length > 0 && filtered[0] !== this.path[0]) {
        filtered.unshift(this.path[0]);
      }

      // Dodaj ostatni punkt, jeśli nie jest już zawarty
      const lastPoint = this.path[this.path.length - 1];
      if (this.path.length > 0 && filtered[filtered.length - 1] !== lastPoint) {
        filtered.push(lastPoint);
      }

      return filtered;
    },
  },
  methods: {
    handleInput() {
      this.$emit('updateSearchInput', this.searchInput);
    },
    handleFromInput() {
      this.$emit('updateSearchInput', this.searchInputFrom);
    },
    removeSelectedRoom() {
      this.$emit('updateSearchInput', '');
      this.$emit('updateSelectedRoom', {});
      this.$emit('updateNavigateButtonClicked', false);
      this.searchInput = '';
      this.showRemoveIcon = false;
    },
    increaseCurrentPoint() {
      if (this.currentPoint == this.filteredIntersectionPath.length - 1) {
        this.$emit('updateNavigateButtonClicked', false);
        // update to make model value blank
        this.$emit('updateSearchInput', '');
        this.$emit('updateSelectedRoom', {});
        this.$emit('updateStart', { p: null });
        this.$emit('updateEnd', { p: null });
        this.$emit('update:modelValue', {
          path: [],
          currentPoint: -1,
        });
      }
      this.currentPoint = Math.min(this.currentPoint + 1, this.pathLength - 1);
    },
    decreaseCurrentPoint() {
      this.currentPoint = Math.max(this.currentPoint - 1, 0);
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.path = newValue.path;
        this.currentPoint = newValue.currentPoint;
        this.pathLength = newValue.path ? newValue.path.length : 1;
        if (this.path.length > 0) {
          this.islandHeight = { height: '4rem' };
        }
        console.log('WATCHER IN DYNAMICISLAND: ', newValue);
      },
      deep: true,
    },
    currentPoint(newValue) {
      this.$emit('update:modelValue', {
        path: this.path,
        currentPoint: newValue,
      });
    },
    selectedRoom(newRoom) {
      // console.log('WATCHER IN DYNAMICISLAND selectedRoom', newRoom);
      this.showRemoveIcon = this.isRoomSelected;
      if (this.isRoomSelected) {
        this.searchInput = newRoom.name;
      } else {
        this.searchInput = '';
      }
    },
    navigateButtonClicked(isClicked) {
      // console.log('WATCHER IN DYNAMICISLAND navigateButtonClicked', isClicked);
      if (isClicked) {
        this.islandHeight = { height: '10rem' };
        this.showRemoveIcon = false;
      }
    },
  },
};
</script>

<style scoped>
@import url('@/assets/css/DynamicIsland.css');

.active {
  background-color: #2af;
  /* IT IS #2af NOT #0af EVERYONE POLEAS ERASOHGLJKSDFHGLJKHUJIKLSDHKGJLFSHLJKGFHSJKLHGLJKSFNVCKLJHSDF LJKGHVLJKSDFHLJKCHVNK<JXN */
}
.visited {
  background-color: black;
}
</style>
