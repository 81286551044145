<template>
  <div class="result_container" v-if="show" :style="{ paddingTop: marginTop }">
    <div
      class="result"
      v-for="room in results"
      :key="room.id"
      @click="sendToMap(room)">
      <span class="result_title">{{ room.name }}</span>
      <span class="result_description"
        >This {{ room.type }} is located on
        {{ room.floor == 0 ? 'the ground floor' : `the ${room.floor} floor` }}
        and in the {{ room.building }}</span
      >
      <div class="img_container">
        <img
          src="@/assets/img/map.png"
          :alt="room.name"
          class="result_image"
          :style="calculateObjectPosition(room.location)" />
      </div>
      <!-- {{ searchInput }} -->
    </div>
  </div>
</template>

<style scoped>
@import url('@/assets/css/SearchResults.css');
</style>

<script>
import rooms from '@/assets/rooms.json';

export default {
  props: {
    searchInput: {
      type: String,
      required: true,
    },
    navigateButtonClicked: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    calculateObjectPosition(location) {
      const img = new Image();
      img.src = require('@/assets/img/map.png');

      return {
        objectPosition: `-${location.x - 300}px -${location.y - 100}px`,
      };
    },

    sendToMap(_room) {
      this.show = false;
      if (!this.navigateButtonClicked) {
        console.log('sendToMap', _room);
        this.$emit('updateSelectedRoom', _room);
      } else if (this.navigateButtonClicked) {
        console.log('sendToMapTo', _room);
        this.$emit('updateSelectedRoomTo', _room);
      }
    },
  },

  mounted() {
    this.results = rooms;
  },

  data() {
    return {
      results: [],
      show: false,
      marginTop: '8rem',
    };
  },

  watch: {
    modelValue: {
      handler(newValue) {
        this.path = newValue.path;
        this.currentPoint = newValue.currentPoint;
      },
      deep: true,
    },
    navigateButtonClicked(ifClicked) {
      // console.log('WATCHER IN SEARCHRESULTS navigateButtonClicked', ifClicked);
      if (ifClicked) {
        this.show = true;
        this.marginTop = '18rem';
      }
    },
    searchInput(newInput) {
      // console.log('WATCHER IN SEARCHRESULTS searchInput', newInput);
      this.show = true;
      const searchWords = newInput.toLowerCase().split(' ');
      this.results = rooms.filter((room) => {
        return searchWords.every((word) =>
          room.name.toLowerCase().includes(word),
        );
      });
    },
  },
};
</script>
