<template>
  <div id="BottomBar" @click="minimize">
    <div class="compass_container" v-if="compassBar">
      <span class="target" :style="{ left: `${targetPercent * 100}%` }"></span>
      <span
        class="current"
        :style="{ left: `${currentPercent * 100}%` }"></span>
    </div>

    <h3 id="BottomTitle">{{ bottomTitle }}</h3>

    <p id="BottomDescription" v-if="!isMinimized">
      {{ bottomDescription }}
    </p>

    <button
      id="BottomButton"
      @click="handleNavigateToClick"
      v-if="bottomButtonDisplay">
      NAVIGATE FROM <img src="@/assets/svg/paper-plane.svg" alt="To" />
    </button>
  </div>
  <div id="popup" v-if="popup">
    <span id="title">Are you sure you are in the correct building?</span>
    <p>We think that you are in {{ popupBuilding }}, but we may be wrong</p>
    <div class="btn_group">
      <button @click="popup = false">Go Back</button>
      <button @click="popup = false">Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomBar',
  props: {
    searchInput: {
      type: String,
      required: false,
    },
    selectedRoom: {
      type: Object,
      required: false,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    eta: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      bottomTitle: 'Loading',
      bottomDescription: '',
      navigateButtonClicked: false,
      isMinimized: false,
      isOnHomeScreen: true,
      bottomButtonDisplay: false,
      bottomBarDisplay: true,

      path: [],
      currentPoint: -1,
      popup: false,
      popupBuilding: 'Unknown',

      navigation: 'Navigation data unavailable.',
      currentAngle: 0,
      targetPercent: 0,
      currentPercent: 0,
      compassBar: false,
    };
  },
  computed: {
    filteredIntersectionPath() {
      const filtered = this.path.filter((dot) => dot.intersection === true);

      if (this.path.length > 0 && filtered[0] !== this.path[0]) {
        filtered.unshift(this.path[0]);
      }

      const lastPoint = this.path[this.path.length - 1];
      if (this.path.length > 0 && filtered[filtered.length - 1] !== lastPoint) {
        filtered.push(lastPoint);
      }

      return filtered;
    },
  },
  methods: {
    handleNavigateToClick() {
      this.$emit('updateNavigateButtonClicked', true);
      this.navigateButtonClicked = true;
      this.bottomButtonDisplay = false;
    },
    minimize() {
      this.isMinimized = !this.isMinimized;
    },
    checkWhatBuildingIn() {
      // this.bottomTitle = 'Loading.';
      this.bottomDescription = '';
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // console.log('Latitude: ', latitude);
            // console.log('Longitude: ', longitude);
            const buildings = require('@/assets/data/buildingGPS.json');

            let closestBuilding = null;
            let closestDistance = Infinity;

            buildings.forEach((building) => {
              const polygon = building.points;
              let centerLat = 0;
              let centerLng = 0;

              polygon.forEach((point) => {
                centerLat += point.lat;
                centerLng += point.lng;
              });

              centerLat /= polygon.length;
              centerLng /= polygon.length;

              const distance = Math.sqrt(
                Math.pow(centerLat - latitude, 2) +
                  Math.pow(centerLng - longitude, 2),
              );

              if (distance < closestDistance) {
                closestDistance = distance;
                closestBuilding = building;
              }
            });

            if (closestBuilding) {
              const distanceInMeters = closestDistance * 111139; // Convert degrees to meters (approximation)
              if (distanceInMeters > 500) {
                this.bottomTitle = 'Too Far from Building';
                this.bottomDescription = '';
                return;
              }
              this.bottomTitle = closestBuilding.name;
              this.bottomDescription = '';
            } else {
              this.bottomTitle = 'Unknown';
              this.bottomDescription = '';
            }
          },
          (error) => {
            console.error('Error getting geolocation: ', error);
            this.bottomTitle = 'Unknown';
            this.bottomDescription = '';
          },
        );
      } else {
        alert('Geolocation is not supported by this browser.');
        this.bottomTitle = 'Unknown';
        this.bottomDescription = '';
      }
    },
    updateNavigation() {
      if (
        this.currentPoint < 0 ||
        this.currentPoint >= this.filteredIntersectionPath.length
      ) {
        this.navigation = 'Oops, something went wrong.';
        return;
      }

      // Check if it's the last point
      if (this.currentPoint === this.filteredIntersectionPath.length - 1) {
        this.navigation = 'Arrived at the destination.';
        this.bottomTitle = 'Arrived';
        // check what building the last point is in
        var lastPoint =
          this.filteredIntersectionPath[
            this.filteredIntersectionPath.length - 1
          ];
        var building = lastPoint.building;
        console.log(building, this.checkWhatBuildingIn());
        if (building !== this.checkWhatBuildingIn()) {
          this.popup = true;
          this.popupBuilding = building;
        }
        return;
      }

      // Check if it's the first point
      if (this.currentPoint === 0) {
        const nextPoint = this.path[this.currentPoint + 1];
        const current = this.path[this.currentPoint];
        const { direction } = this.getDirectionAndDistance(current, nextPoint);

        this.bottomTitle = `Go ${direction} - ` + this.eta + 'mins';
        return;
      }

      // Handle intermediate points
      const current = this.filteredIntersectionPath[this.currentPoint];
      const currentPointIndexInPath = this.path.findIndex(
        (point) => point === current,
      );

      if (currentPointIndexInPath === -1) {
        this.navigation = 'Oops, something went wrong.';
        return;
      }

      const nextPoint = this.path[currentPointIndexInPath + 1];
      const prevPoint = this.path[currentPointIndexInPath - 1];
      const nextIntersection =
        this.filteredIntersectionPath[this.currentPoint + 1];
      const previoutIntersection =
        this.filteredIntersectionPath[this.currentPoint - 1];

      if (
        !nextPoint ||
        !prevPoint ||
        !nextIntersection ||
        !previoutIntersection
      ) {
        this.navigation = 'Oops, something went wrong.';
        return;
      }

      const turnAngle = this.getTurnAngle(prevPoint, current, nextPoint);
      const distance = this.getDistance(current, nextIntersection);

      if (turnAngle > -45 && turnAngle <= 45) {
        this.compassBar = false;
        this.navigation = `Go straight for ${distance.toFixed(2)} meters.`;
        this.bottomTitle = 'Go Straight';
      } else if (turnAngle > 45 && turnAngle <= 135) {
        this.compassBar = false;
        this.navigation = `Turn right and go straight for ${distance.toFixed(
          2,
        )} meters.`;
        this.bottomTitle = 'Turn Right';
      } else if (turnAngle > -135 && turnAngle <= -45) {
        this.compassBar = false;
        this.navigation = `Turn left and go straight for ${distance.toFixed(
          2,
        )} meters.`;
        this.bottomTitle = 'Turn Left';
      } else {
        this.compassBar = false;
        this.navigation = `Turn back and go straight for ${distance.toFixed(
          2,
        )} meters.`;
        this.bottomTitle = 'Go Back';
      }
    },

    getDirectionAndDistance(pointA, pointB) {
      const dx = pointB.x - pointA.x;
      const dy = pointB.y - pointA.y;

      let direction = '';
      if (Math.abs(dy) > Math.abs(dx)) {
        direction = dy > 0 ? 'north' : 'south';
      } else {
        direction = dx > 0 ? 'east' : 'west';
      }

      this.navigation =
        'Follow the Compass Bar - Route Eta: ' + this.eta + ' minutes';

      const degree = Math.atan2(dy, dx) * (180 / Math.PI);
      const degPercent = (degree + 180) / 360;

      this.targetPercent = degPercent;
      if (this.currentPercent != null || this.currentPercent != undefined) {
        this.compassBar = true;
      } else {
        this.compassBar = false;
      }

      const distance = Math.sqrt(dx ** 2 + dy ** 2);
      return { direction, distance };
    },

    getDistance(pointA, pointB) {
      const dx = pointB.x - pointA.x;
      const dy = pointB.y - pointA.y;
      return Math.sqrt(dx ** 2 + dy ** 2) / 10;
    },

    getTurnAngle(prevPoint, currentPoint, nextPoint) {
      const vec1 = {
        x: currentPoint.x - prevPoint.x,
        y: currentPoint.y - prevPoint.y,
      };
      const vec2 = {
        x: nextPoint.x - currentPoint.x,
        y: nextPoint.y - currentPoint.y,
      };

      const dotProduct = vec1.x * vec2.x + vec1.y * vec2.y;
      const magnitude1 = Math.sqrt(vec1.x ** 2 + vec1.y ** 2);
      const magnitude2 = Math.sqrt(vec2.x ** 2 + vec2.y ** 2);

      // Calculate the angle in radians
      const angleRadians = Math.acos(dotProduct / (magnitude1 * magnitude2));

      // Use the cross product to determine the sign of the angle
      const crossProduct = vec1.x * vec2.y - vec1.y * vec2.x;
      const angleDegrees = (angleRadians * 180) / Math.PI;

      return crossProduct < 0 ? -angleDegrees : angleDegrees;
    },
  },
  mounted() {
    this.checkWhatBuildingIn();

    // Periodic check
    setInterval(() => {
      if (this.isOnHomeScreen) {
        this.checkWhatBuildingIn();
      }
    }, 1000);

    window.addEventListener('deviceorientation', (event) => {
      console.log('deviceorientation event: ', event);
      if (event.alpha !== null) {
        this.currentAngle = event.alpha;
        this.currentPercent = this.currentAngle / 360;

        // check if the current percent is in between 5% of the target percent
        if (
          this.currentPercent >= this.targetPercent - 0.1 &&
          this.currentPercent <= this.targetPercent + 0.1
        ) {
          this.currentPercent = this.targetPercent;
        }
      } else {
        this.compassBar = false;
      }
    });
  },

  watch: {
    modelValue: {
      handler(newValue) {
        this.path = newValue.path;
        this.currentPoint = newValue.currentPoint;
        console.log('WATCHER IN BOTTOM BAR: ', newValue);
      },
      deep: true,
    },
    path(newValue) {
      this.bottomDescription = this.navigation;
      this.isMinimized = false;
      this.updateNavigation();
      this.$emit('update:modelValue', {
        path: newValue,
        currentPoint: this.currentPoint,
      });
    },
    currentPoint(newValue) {
      this.updateNavigation();
      this.bottomDescription = this.navigation;
      this.$emit('update:modelValue', {
        path: this.path,
        currentPoint: newValue,
      });
    },
    selectedRoom(room) {
      console.log('WATCHER IN BOTTOMBAR selectedRoom: ', room);
      if (room.name) {
        if (!room || room.name.length < 1) {
          // if no room is selected or room is empty
          this.isOnHomeScreen = true;
          this.checkWhatBuildingIn();
          // console.log('NO ROOM SELECTED');
          return;
        }
      } else {
        // if no room is selected or room is empty
        // console.log('NO ROOM SELECTED');
        this.checkWhatBuildingIn();
        this.isOnHomeScreen = true;
        this.bottomButtonDisplay = false;
        return;
      }
      this.isOnHomeScreen = false;
      // if a room is selected

      this.bottomTitle = room.name;
      this.bottomDescription = `This ${room.type} is located on ${
        room.floor == 0 ? 'the ground floor' : 'the ' + room.floor + ' floor'
      } and in the ${room.building}`;

      this.bottomBarDisplay = true;
      // console.log('NEW ROOM: ', room);

      this.bottomButtonDisplay = true;
    },
  },
};
</script>

<style scoped>
@import url('@/assets/css/BottomBar.css');
</style>
